<template>
  <div class="pt-4 pl-4 pb-4 pr-4">
    <div>
      <div>
        <h3 class="pb-3">Cadastradores SOF do SIOP</h3>
        <div>Abaixo lista de Cadastradores SOF que poderão ser agrupados por Departamento ou Unidade Cadastradora (SOF e Unidades Cadastradoras abaixo dela).</div>
        <div class="font-italic pt-3 pb-4" style="font-size: 13px;">Obs.: Departamentos ou Unidades Cadastradoras que não possuírem Cadastradores não aparecerão na relação.</div>
      </div>

      <div>
        <em>Agrupar Cadastradores por:</em>
        <div class="pt-2">
          <input type="radio" :disabled="departamentos.length == 0 && unidades.length == 0" v-model="agruparPor" value="departamento"> Departamento
          <input type="radio" :disabled="departamentos.length == 0 && unidades.length == 0" v-model="agruparPor" value="unidadeCadastradora" style="margin-left: 30px;"> Unidade Cadastradora
        </div>
      </div>

      <template v-if="agruparPor == 'departamento'">
        <div class="col-12 pt-4" v-for="(departamento,index) of departamentos" :key="index">
            <h5>{{departamento.sigla && departamento.sigla.trim()!=='' ? departamento.sigla + ' - ' : ''}}{{departamento.nome}}</h5>
            <table class="table table-striped table-bordered table-hover">
              <thead>
                    <tr>
                        <th scope="col" width="45%">
                            <div class="flex-grow-1 text-left">Nome</div>
                        </th>
                        <th scope="col" width="20%">
                            <div class="flex-grow-1 text-left">Telefone</div>
                        </th>
                        <th scope="col" width="35%">
                            <div class="flex-grow-1 text-left">Email</div>
                        </th>
                    </tr>
              </thead>
              <tbody>
                <tr v-for="(cadastrador,i) of departamento.cadastradores" :key="i+'c'">
                    <td>{{cadastrador.nome}}</td>
                    <td>{{mascaraTelefone(cadastrador.telefone)}}</td>
                    <td><a :href="'mailto:'+cadastrador.email">{{cadastrador.email}}</a></td>
                </tr>
              </tbody>
            </table>
        </div>
      </template>

      <template v-if="agruparPor == 'unidadeCadastradora'">
        <div class="col-12 pt-4" v-for="(unidade,index) of unidades" :key="index">
            <h5>{{unidade.id + ' - ' + unidade.nome}}{{unidade.sigla && unidade.sigla.trim()!=='' ? ' - ' + unidade.sigla : ''}}</h5>
            <table class="table table-striped table-bordered table-hover">
              <thead>
                    <tr>
                        <th scope="col" width="45%">
                            <div class="flex-grow-1 text-left">Nome</div>
                        </th>
                        <th scope="col" width="20%">
                            <div class="flex-grow-1 text-left">Telefone</div>
                        </th>
                        <th scope="col" width="35%">
                            <div class="flex-grow-1 text-left">Email</div>
                        </th>
                    </tr>
              </thead>
              <tbody>
                <tr v-for="(cadastrador,i) of unidade.cadastradores" :key="i+'c'">
                    <td>{{cadastrador.nome}}</td>
                    <td>{{mascaraTelefone(cadastrador.telefone)}}</td>
                    <td><a :href="'mailto:'+cadastrador.email">{{cadastrador.email}}</a></td>
                </tr>
              </tbody>
            </table>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag'
import bus from '../bus.js'

const queryUnidades = gql`query cadastradoresPorUnidade ($unidadesCadastradoras: [String!]) {
    cadastradoresPorUnidade (unidadesCadastradoras: $unidadesCadastradoras) {
        id, nome, sigla, cadastradores{ nome, telefone, email }
    }
}`

const queryDepartamentos = gql`query cadastradoresPorDepartamento {
    cadastradoresPorDepartamento {
        id: idDepartamento,
        nome: nomeDepartamento,
        sigla: siglaDepartamento,
        cadastradores{ nome, telefone, email }
    }
}`

export default {
  apollo: {
    unidades: {
      query: queryUnidades,
      client: 'usuarios',
      variables () {
        return { unidadesCadastradoras: ['00001C', '00010C', '00011C'] }
      },
      update (data) {
        let unidades = []
        unidades = JSON.parse(JSON.stringify(data.cadastradoresPorUnidade))
        unidades = unidades.sort((a, b) => a.id.localeCompare(b.id, 'pt-br'))
        unidades.forEach((uc) => {
          uc.cadastradores = uc.cadastradores.sort((a, b) => a.nome.localeCompare(b.nome, 'pt-br'))
        })

        return unidades
      },
      error (err) {
        console.log(err)
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', '<strong>Falha ao carregar dados dos cadastradores locais.</strong>')
      }
    },
    departamentos: {
      query: queryDepartamentos,
      client: 'usuarios',
      update (data) {
        let departamentos = []
        departamentos = JSON.parse(JSON.stringify(data.cadastradoresPorDepartamento))
        departamentos = departamentos.sort((a, b) => a.nome.localeCompare(b.nome, 'pt-br'))
        departamentos.forEach((uc) => {
          uc.cadastradores = uc.cadastradores.sort((a, b) => a.nome.localeCompare(b.nome, 'pt-br'))
        })

        return departamentos
      },
      error (err) {
        console.log(err)
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', '<strong>Falha ao carregar dados dos cadastradores locais.</strong>')
      }
    }
  },
  data: function () {
    return {
      unidades: [],
      departamentos: [],
      agruparPor: 'departamento'
    }
  },
  methods: {
    mascaraTelefone (v) {
      if (v) {
        v = String(v)
        v = v.replace(/\D/g, '')
        v = v.replace(/^(\d{2})(\d)/g, '($1) $2')
        v = v.replace(/(\d)(\d{4})$/, '$1-$2')
      }
      return v
    }
  }
}
</script>
<style scoped src="@siop/standard-libui/dist/css/bootstrap-4/siop.css" />
