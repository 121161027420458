<template>
  <card id="noticias" titulo="Fique por dentro" classe="box big box-texto" cor="cinza">
    <div v-if="noticias == null">{{ mensagem }}</div>
    <ul v-else class="resumo">
      <li v-for="noticia in noticias" v-bind:key="noticia.titulo">
        <article>
          <h3><a>{{ noticia.titulo }}</a></h3>
          <p class="data"><time pubdate>{{dateFormatter(dateJsonToHtml(noticia.datainclusao))}}</time></p>
          <div class="noticia-texto" v-html="noticia.conteudo"></div>
        </article>
      </li>
    </ul>
  </card>
</template>

<script>
import Card from '../Card.vue'
import gql from 'graphql-tag'

const query = gql`query noticiasRecentesExternas($n: Int) {
    noticiasRecentesExternas(n: $n) {
      titulo
      conteudo
      datainclusao
    }
  }`
export default {
  data: function () {
    return {
      mensagem: 'Carregando...',
      noticias: []
    }
  },
  components: {
    card: Card
  },
  apollo: {
    noticias: {
      query,
      client: 'comunicacao',
      variables: { n: 5 },
      update (data) {
        return data.noticiasRecentesExternas
      },
      error (err) {
        console.log(err)
        this.mensagem = 'Falha ao carregar notícias'
      }
    }
  },
  mounted () {
    this.mensagem = 'Carregando notícias...'
  },
  methods: {
    uri (noticia) {
      const titulo = encodeURI(noticia.titulo)
      const conteudo = encodeURI(noticia.conteudo)
      return `/noticia?titulo=${titulo}&conteudo=${conteudo}`
    },
    dateJsonToHtml (value) {
      if (value && value.endsWith('Z')) {
        value = value.slice(0, -1)
      }
      return value
    },
    dateFormatter (value) {
      return new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short', dateStyle: 'short' }).format(new Date(value))
    }
  }
}
</script>
