<template>
  <div class="mid">
    <card titulo="Acesso ao SIOP" class="box login" style="margin-bottom: 5%;">
      <div class="corpo">
        <p>Já tem usuário? Faça o login e bom trabalho:</p><br>
        <div class="box-acesso">
          <div style="text-align: center;">
            <button
            style="font-family: Rawline, Raleway, sans-serif;"
            :title="!metodosAutenticacao.includes('loginunico')?'Autenticação com gov.br desabilitada':'Entre com a sua conta gov.br'"
            class="br-sign-in primary large" @click="loginUnico()" :disabled="!metodosAutenticacao.includes('loginunico')" type="button">
             <i class="fas fa-user" aria-hidden="true"/>&nbsp;Entrar com&nbsp;<span class="text-black">gov.br</span>
            </button>
          </div>
        </div>
        <hr><br>
        <a href="#" @click="$refs.modalAutenticacao.mostra()" style="font-size: small;" >
          Clique aqui para acessar com CPF/Senha do SIOP
        </a>
      </div>
    </card>
    <AutenticacaoSIOP ref="modalAutenticacao"></AutenticacaoSIOP>
</div>
</template>
<script>
import Card from './../Card.vue'
import UiConfig from '../../config/ui'
import bus from '../../bus.js'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { load } from 'recaptcha-v3'
import AutenticacaoSIOP from './AutenticacaoSIOP.vue'

const queryMetodosAutenticacao = gql`query metodosAutenticacaoHabilitados {
    metodosAutenticacaoHabilitados
  }`
const queryChaveRecaptcha = gql`query chaveRecaptcha {
    chaveRecaptcha
  }`
const mutationRecuperarSenha = gql`mutation recuperarSenhaUsuario($cpf: String, $email: String!, $recaptcha: String) {
    recuperarSenhaUsuario(cpf: $cpf, email: $email, recaptcha: $recaptcha)
  }`
export default {
  name: 'Login',
  components: {
    card: Card,
    AutenticacaoSIOP
  },
  data: function () {
    return {
      tela: UiConfig.tela.login.padrao,
      login: '',
      senha: '',
      cpf: '',
      email: '',
      recaptchaLoaded: false,
      metodosAutenticacao: [],
      chaveRecaptcha: null,
      urls: UiConfig.urls,
      formValidado: false
    }
  },
  methods: {
    isNumber (evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault()
      }
    },
    validar (escopo) {
      this.mensagemErro = ''
      if (this.isTelaLogin() && this.validaCPF(this.login) && this.senha.length > 0) {
        this.submitLogin()
        return
      }
      if (this.isTelaEsqueciSenha() && this.validaCPF(this.login) && this.validaEmail(this.email)) {
        this.submitEsqueciSenha()
        return
      }
      this.formValidado = true
    },
    validaCPF (cpf) {
      const validRegex = /\d{11}/
      return cpf.match(validRegex)
    },
    validaEmail (email) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      return email.match(validRegex)
    },
    submitLogin () {
      bus.emit('siop-limpa-tudo')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('usuario')
      const bcrypt = require('bcryptjs')
      const Md5 = require('md5.js')
      const salt = bcrypt.genSaltSync(11)
      const senhaMd5 = new Md5().update(this.senha).digest('hex')
      const senhaEncriptada = bcrypt.hashSync(senhaMd5, salt)
      const cpf = this.login.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
      const usuario = { cpf, senha: senhaEncriptada }
      const app = this
      axios.post(UiConfig.urls.auth.login, usuario).then(
        // callback
        function (res) {
          window.localStorage.setItem('token', res.data.tokenSiop)
          const usuario = jwtDecode(window.localStorage.getItem('token'))
          if (usuario.trocarSenha) {
            bus.emit('siop-limpa-tudo')
            app.$router.push('trocarsenha')
          } else {
            document.location = UiConfig.paths.default
          }
        },
        // exception
        function (err) {
          let mensagemErro
          if (err) {
            if (err.response.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else {
              mensagemErro = 'Falha ao solicitar autenticação'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    },
    async submitEsqueciSenha () {
      try {
        bus.emit('siop-limpa-tudo')
        if (!this.chaveRecaptcha) {
          bus.emit('siop-erro', 'Falha ao solicitar nova senha, chave recaptcha não disponível')
          return
        }
        load(this.chaveRecaptcha, { explicitRenderParameters: { badge: 'bottomleft' } }).then((recaptcha) => {
          recaptcha.execute('recuperarSenha').then((token) => {
            const cpf = this.login.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')
            this.$apollo.mutate({
              mutation: mutationRecuperarSenha,
              variables: { cpf, email: this.email, recaptcha: token },
              client: 'login'
            }).then((data) => {
              this.tela = UiConfig.tela.login.senhaEnviada
              bus.emit('siop-limpa-tudo')
              return true
            }).catch((error) => {
              console.log(JSON.stringify(error))
              if (error && error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0].message) {
                bus.emit('siop-erro', 'Falha ao solicitar nova senha: ' + error.graphQLErrors[0].message)
              } else {
                bus.emit('siop-erro', 'Falha ao solicitar nova senha')
              }
              return false
            })
          }).catch((err) => {
            console.log(err)
            bus.emit('siop-erro', 'Falha ao solicitar nova senha, falha ao executar o recaptcha')
          })
        }).catch((err) => {
          console.log(err)
          bus.emit('siop-erro', 'Falha ao solicitar nova senha, falha ao carregar o recaptcha')
        })
      } catch (error) {
        console.log(error)
        bus.emit('siop-erro', 'Falha ao solicitar nova senha, recaptcha não carregado')
      }
    },
    isTelaLogin () {
      return this.tela === UiConfig.tela.login.padrao
    },
    isTelaEsqueciSenha () {
      return this.tela === UiConfig.tela.login.esqueciSenha
    },
    isTelaSenhaEnviada () {
      return this.tela === UiConfig.tela.login.senhaEnviada
    },
    isTelaTrocaSenhaDesativada () {
      return this.tela === UiConfig.tela.login.trocaSenhaDesativada
    },
    clicouEsqueciSenha () {
      this.formValidado = false
      this.cpf_senha = ''
      this.tela = UiConfig.tela.login.esqueciSenha
      bus.emit('siop-limpa-tudo')
    },
    cancelarEsqueciSenha () {
      this.tela = UiConfig.tela.login.padrao
      bus.emit('siop-limpa-tudo')
    },
    voltar () {
      this.tela = UiConfig.tela.login.padrao
    },
    loginNeoid () {
      const url = UiConfig.urls.auth.loginNeoid
      axios.get(url).then(
        // callback
        function (res) {
          document.location = res.data.URL
        },
        // exception
        function (err) {
          let mensagemErro
          if (err) {
            if (err.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else if (err.response.status === 500) {
              mensagemErro = 'Falha no serviço de autenticação'
            } else {
              mensagemErro = 'Falha ao solicitar autenticação'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    },
    loginUnico () {
      const url = UiConfig.urls.auth.loginUnico
      axios.get(url).then(
        // callback
        function (res) {
          document.location = res.data.URL
        },
        // exception
        function (err) {
          let mensagemErro
          if (err) {
            if (err.response.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else if (err.response.status === 500) {
              mensagemErro = 'Falha no serviço de autenticação'
            } else {
              mensagemErro = 'Falha ao solicitar autenticação'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    },
    loginCertificado () {
      const url = UiConfig.urls.auth.loginCertificado
      axios.get(url).then(
        // callback
        function (res) {
          window.localStorage.setItem('token', res.data.tokenSiop)
          document.location = UiConfig.paths.default
        },
        // exception
        function (err) {
          let mensagemErro
          if (err) {
            if (err.response.status === 404) {
              mensagemErro = 'Serviço de autenticação não encontrado'
            } else if (err.response.status === 400 || err.response.status === 401) {
              mensagemErro = err.response.data.err
            } else if (err.response.status === 500) {
              mensagemErro = 'Falha no serviço de autenticação'
            } else {
              mensagemErro = 'Falha ao solicitar autenticação'
            }
          } else {
            mensagemErro = 'Falha ao solicitar autenticação'
          }
          bus.emit('siop-limpa-tudo')
          bus.emit('siop-erro', mensagemErro)
        })
    }
  },
  apollo: {
    chaveRecaptcha: {
      query: queryChaveRecaptcha,
      client: 'login',
      skip () { return this.recaptchaLoaded || this.tela !== UiConfig.tela.login.esqueciSenha },
      error (err) {
        console.log(err)
        this.erro = true
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', 'Falha ao inicializar recaptcha')
      }
    },
    metodosAutenticacao: {
      query: queryMetodosAutenticacao,
      client: 'login',
      update (data) {
        return data.metodosAutenticacaoHabilitados
      },
      error (err) {
        console.log(err)
        this.erro = true
        bus.emit('siop-limpa-tudo')
        bus.emit('siop-erro', `<strong>Falha na configuração de autenticação do SIOP.</strong> <p>[${err.graphQLErrors[0].message}]</p>`)
      }
    }
  },
  mounted () {
    const token = window.localStorage.getItem('token')
    let usuario = {}
    try {
      if (token != null && token !== '' && token !== 'null') {
        usuario = jwtDecode(token)
        if (usuario != null && usuario.trocarSenha) {
          this.$router.push('trocarsenha')
        }
      }
    } catch (error) {
      console.log('-->', error)
    }
  }
}
</script>
<style scoped>
.alert-danger {
  top: 124px !important;
}
</style>
